import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import AuthReducer from './AuthReducer/AuthReducer';
import EmployeeRewardsReducer from './EmployeeRewardsReducer/EmployeeRewardsReducer';
import history from '../../Router/History';

const appReducer = combineReducers({
  router: connectRouter(history),
  auth: AuthReducer,
  employeeRewards: EmployeeRewardsReducer
});

const RootReducer = (state, action) => {
  return appReducer(state, action);
};

export default RootReducer;
