import * as types from '../Types';

export const CATEGORY_LIST_API = 'rewards/categories';
export const ADD_REWARD_API = 'admin/multiple-rewards';
export const APPRECIATION_LIST_API = 'rewards/appreciation-list';
export const USER_APPRECIATION_LIST_API = 'rewards/appreciation-list?employee_id=';
export const PIGGY_BANK_BALANCE_LIST_API = 'rewards/appreciation-list/pb';
export const APPRECIATION_API = 'admin/slack/rewards';
export const UPCOMING_REWARDS_API = 'rewards/open-upcoming';
export const REDEEM_REWARD_API = 'redeem/';

// ESLint and Template strings don't work too well
export const REMOVE_REWARD_API = 'admin/rewards/';
export const USER_REWARD_LIST_API = 'rewards?employee_id=';

export function fetchRewardsForEmployee(employeeData) {
  return {
    types: [
      types.LOAD_REWARDS_LIST,
      types.LOAD_REWARDS_LIST_SUCCESS,
      types.LOAD_REWARDS_LIST_FAILURE
    ],
    payload: employeeData,
    promise: client => {
      const { id } = employeeData;
      return client.get(USER_REWARD_LIST_API + id);
    }
  };
}

export function fetchRewardCategoryList() {
  return {
    types: [
      types.LOAD_CATEGORIES_LIST,
      types.LOAD_CATEGORIES_LIST_SUCCESS,
      types.LOAD_CATEGORIES_LIST_FAILURE
    ],
    promise: client => client.get(CATEGORY_LIST_API)
  };
}

export function addReward(data) {
  return {
    types: [types.ADD_REWARD, types.ADD_REWARD_SUCCESS, types.ADD_REWARD_FAILURE],
    promise: client => client.post(ADD_REWARD_API, data)
  };
}

export function removeReward(id) {
  return {
    types: [types.REMOVE_REWARD, types.REMOVE_REWARD_SUCCESS, types.REMOVE_REWARD_FAILURE],
    promise: client => client.delete(REMOVE_REWARD_API + id)
  };
}

export function clearRewardsData() {
  return {
    type: types.CLEAR_REWARDS_LIST
  };
}

export function selectCategory(categoryData) {
  return {
    type: types.HIGHLIGHT_CATEGORY,
    payload: categoryData
  };
}

export function fetchEmployeeAppreciationList(employeeData) {
  return {
    types: [
      types.LOAD_APPRECIATION_LIST,
      types.LOAD_APPRECIATION_LIST_SUCCESS,
      types.LOAD_APPRECIATION_LIST_FAILURE
    ],
    payload: employeeData,
    promise: client => {
      const { id } = employeeData;
      return client.get(USER_APPRECIATION_LIST_API + id);
    }
  };
}

export function fetchAppreciationRewardList() {
  return {
    types: [
      types.LOAD_APPRECIATION_TRANSACTION_LIST,
      types.LOAD_APPRECIATION_TRANSACTION_LIST_SUCCESS,
      types.LOAD_APPRECIATION_TRANSACTION_LIST_FAILURE
    ],
    promise: client => client.get(APPRECIATION_LIST_API)
  };
}

export function fetchPiggyBankBalanceList() {
  return {
    types: [
      types.LOAD_PIGGY_BANK_BALANCE_LIST,
      types.LOAD_PIGGY_BANK_BALANCE_LIST_SUCCESS,
      types.LOAD_PIGGY_BANK_BALANCE_LIST_FAILURE
    ],
    promise: client => client.get(PIGGY_BANK_BALANCE_LIST_API)
  };
}

export function clearCategorySelection() {
  return {
    type: types.UNHIGHLIGHT_CATEGORY
  };
}

export function appretiateNow(data) {
  return {
    types: [types.APPRECIATE, types.APPRECIATE_SUCCESS, types.APPRECIATE_FAILURE],
    promise: client => client.post(APPRECIATION_API, data)
  };
}

export function fetchUpcomingRewards() {
  return {
    types: [
      types.LOAD_UPCOMING_REWARDS_LIST,
      types.LOAD_UPCOMING_REWARDS_LIST_SUCCESS,
      types.LOAD_UPCOMING_REWARDS_LIST_FAILURE
    ],
    promise: client => client.get(UPCOMING_REWARDS_API)
  };
}

export function redeemReward(id) {
  return {
    types: [types.REDEEM_REWARD, types.REDEEM_REWARD_SUCCESS, types.REDEEM_REWARD_FAILURE],
    promise: client => client.post(REDEEM_REWARD_API + id)
  };
}
