import * as types from '../../Types';
import {
  readRecord,
  storeRecord,
  deleteRecord,
  clearAll
} from '../../../Services/LocalStorageService';

const LSKEY = 'authData';

export const authDataFromLS = () => {
  return readRecord(LSKEY) || {};
};

const initialState = {
  authData: authDataFromLS(),
  loading: null,
  forceLogout: null,
  error: ''
};

const RewardReducer = (state = initialState, action) => {
  let result;
  switch (action.type) {
    case types.LOGIN:
      return {
        ...state,
        authData: {},
        loading: false,
        forceLogout: null,
        error: ''
      };
    case types.DESTROY_AUTH_SESSION:
      return {
        ...initialState
      };

    case types.LOGIN_SUCCESS: {
      result = action.result || {};
      storeRecord(LSKEY, result);
      return {
        ...state,
        authData: result,
        loading: false
      };
    }

    case types.LOGIN_FAILURE: {
      deleteRecord(LSKEY);
      return {
        ...state,
        loading: false,
        error: action.error.message
      };
    }

    case types.CLEAR_AUTH_DATA: {
      // case types.DESTROY_SESSION:
      // We don't need to do this state update in case of DESTROY_SESSION because by then
      // CLEAR_AUTH_DATA would already have been called once
      clearAll();
      return {
        ...state,
        authData: {},
        forceLogout: true
      };
    }

    default:
      return state;
  }
};

export default RewardReducer;
