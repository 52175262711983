import { toast } from 'react-toastify';

export const configureToast = () => {
  toast.configure({
    bodyClassName: 'toast-body'
  });
};

export const showSuccessToastMessage = message => {
  toast.success(message);
};

export const showErrorToastMessage = message => {
  toast.error(message);
};
