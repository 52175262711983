import * as types from '../Types';

export const ADD_EMPLOYEE_API = 'admin/employees';
export const REMOVE_EMPLOYEE_API = 'employees/rewards?id=';
export const ADD_CATEGORY_API = 'admin/reward-categories/';
export const REMOVE_CATEGORY_API = 'admin/reward-categories?id=';
// ESLint and Template strings don't work too well

export function addEmployee(data) {
  return {
    types: [types.ADD_EMPLOYEE, types.ADD_EMPLOYEE_SUCCESS, types.ADD_EMPLOYEE_FAILURE],
    promise: client => client.post(ADD_EMPLOYEE_API, data)
  };
}

export function updateEmployee(data, currentEmployeeId) {
  return {
    types: [types.UPDATE_EMPLOYEE, types.UPDATE_EMPLOYEE_SUCCESS, types.UPDATE_EMPLOYEE_FAILURE],
    promise: client => client.put(`${ADD_EMPLOYEE_API}/${currentEmployeeId}`, data)
  };
}

export function removeEmployee(id) {
  return {
    types: [types.REMOVE_EMPLOYEE, types.REMOVE_EMPLOYEE_SUCCESS, types.REMOVE_EMPLOYEE_FAILURE],
    promise: client => client.delete(`${ADD_EMPLOYEE_API}/${id}`)
  };
}

export function addCategory(data) {
  return {
    types: [types.ADD_CATEGORY, types.ADD_CATEGORY_SUCCESS, types.ADD_CATEGORY_FAILURE],
    promise: client => client.post(ADD_CATEGORY_API, data)
  };
}

export function updateCategory(data, currentCategoryId) {
  return {
    types: [types.UPDATE_CATEGORY, types.UPDATE_CATEGORY_SUCCESS, types.UPDATE_CATEGORY_FAILURE],
    promise: client => client.put(ADD_CATEGORY_API + currentCategoryId, data)
  };
}

export function deleteCategory(id) {
  return {
    types: [types.REMOVE_CATEGORY, types.REMOVE_CATEGORY_SUCCESS, types.REMOVE_CATEGORY_FAILURE],
    promise: client => client.delete(ADD_CATEGORY_API + id)
  };
}
