/* eslint-disable no-console */
import { SUCCESS_DEFAULT, FAILURE_DEFAULT, HTTP_CLIENT, CLEAR_AUTH_DATA } from '../Types';

export default function clientMiddleware(httpClient) {
  return ({ dispatch, getState }) => {
    return next => action => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }
      // eslint-disable-next-line no-unused-vars
      const { promise, types, handler = HTTP_CLIENT, ...rest } = action;

      if (!promise) {
        return next(action);
      }
      const [REQUEST, SUCCESS = SUCCESS_DEFAULT, FAILURE = FAILURE_DEFAULT] = types;
      next({ ...rest, type: REQUEST });
      const actionPromise = promise(httpClient, { ...getState() });
      actionPromise
        .then(
          result => {
            if (result && result.data) {
              next({ ...rest, result: result.data, type: SUCCESS });
            } else if (result && result.error && result.error.message) {
              const error = {
                message: result.error.message
              };
              next({ ...rest, error, type: FAILURE });
            } else {
              const error = {
                message: 'Unexpected error occured, try again later'
              };
              next({ ...rest, error, type: FAILURE });
            }
          },
          error => {
            const { status } = error.response || { status: 'UNK', message: error };
            if (!error.message) {
              error.message = 'Unexpected error occured, try again later';
            }
            error.status = status;
            if (status === 401) {
              // if status in 401 logout user
              next({ ...rest, type: FAILURE });
              return next({ ...rest, error, type: CLEAR_AUTH_DATA });
            }
            return next({ ...rest, error, type: FAILURE });
          }
        )
        .catch(error => {
          console.error('MIDDLEWARE ERROR:', error);
        });

      return actionPromise;
    };
  };
}
