import * as types from '../../Types';

export const EMPLOYEE_LIST_API = 'employees';
export const PROFILE_API = 'profile/me';
// ESLint and Template strings don't work too well
export const LEADERBOARD_DATA_API = 'leaderboard/podium';
export const CHECKPOINT_API = 'special/checkpoint';

export function fetchEmployees() {
  return {
    types: [
      types.LOAD_EMPLOYEE_LIST,
      types.LOAD_EMPLOYEE_LIST_SUCCESS,
      types.LOAD_EMPLOYEE_LIST_FAILURE
    ],
    promise: client => client.get(EMPLOYEE_LIST_API)
  };
}

export function selectEmployee(employeeData) {
  return {
    type: types.HIGHLIGHT_EMPLOYEE,
    payload: employeeData
  };
}

export function clearEmployeeSelection() {
  return {
    type: types.UNHIGHLIGHT_EMPLOYEE
  };
}

export function clearEmployeeData() {
  return {
    type: types.CLEAR_EMPLOYEE_LIST
  };
}

export function fetchProfileData() {
  return {
    types: [
      types.LOAD_PROFILE_DATA,
      types.LOAD_PROFILE_DATA_SUCCESS,
      types.LOAD_PROFILE_DATA_FAILURE
    ],
    promise: client => {
      return client.get(PROFILE_API);
    }
  };
}
export function fetchLeaderboardData(data) {
  return {
    types: [
      types.LOAD_LEADERBOARD_DATA,
      types.LOAD_LEADERBOARD_DATA_SUCCESS,
      types.LOAD_LEADERBOARD_DATA_FAILURE
    ],
    promise: client => {
      return client.get(`${LEADERBOARD_DATA_API}/${data}`);
    }
  };
}

export function fetchCheckpointData() {
  return {
    types: [
      types.LOAD_CHECKPOINT_DATA,
      types.LOAD_CHECKPOINT_DATA_SUCCESS,
      types.LOAD_CHECKPOINT_DATA_FAILURE
    ],
    promise: client => {
      return client.get(CHECKPOINT_API);
    }
  };
}
