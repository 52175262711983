import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { MuiThemeProvider } from '@material-ui/core';

import { configureToast } from '../../Services/ToastService';
import RouteSwitcher from '../../Router/RouteSwitcher';
import Routes, { RouteUrls } from '../../Router/Routes';
import { AuthActions } from '../../Store/Actions';

import './AppComponent.scss';
import { muiTheme } from '../../Styles/theme';

const LoaderComponent = React.lazy(() => import('../../Components/Loader/LoaderComponent'));
const WrapperComponent = React.lazy(() => import('../../Components/Wrapper/WrapperComponent'));
const PageLayoutComponent = React.lazy(() =>
  import('../../Components/PageLayout/PageLayoutComponent')
);

configureToast();

const mapStateToProps = state => ({
  auth: state.auth,
  employeeRewards: state.employeeRewards
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...AuthActions }, dispatch)
});

class AppComponent extends React.PureComponent {
  componentDidMount() {
    const {
      history,
      auth: {
        authData: { token_id: tokenId }
      }
    } = this.props;
    const { pathname } = history.location;
    if (!tokenId) {
      if (pathname !== '/') history.push('/');
    } else if (pathname === '/') {
      history.push(RouteUrls.rewardDashboard);
    }
  }

  componentDidUpdate() {
    const {
      actions,
      history,
      auth: { forceLogout }
    } = this.props;
    if (forceLogout) {
      actions.destroyAuthSession();
      actions.destroySession();
      if (history.location.pathname !== '/') {
        history.push('/');
      }
    }
  }

  render() {
    const {
      auth: { loading: authLoading },
      employeeRewards: { loading: employeeRewardsLoading },
      auth: {
        authData: { token_id: tokenId }
      }
    } = this.props;
    const loading = authLoading || employeeRewardsLoading;
    const appComponentClassList = ['app'];
    if (loading) appComponentClassList.push('loader-active');

    return (
      <React.Suspense fallback={<div>Loading...</div>} data-test="component-AppComponent">
        <LoaderComponent show={loading} />
        <div className={appComponentClassList.join(' ')}>
          <MuiThemeProvider theme={muiTheme}>
            <PageLayoutComponent isLogin={tokenId}>
              <WrapperComponent>
                <RouteSwitcher routes={Routes} />
              </WrapperComponent>
            </PageLayoutComponent>
          </MuiThemeProvider>
        </div>
      </React.Suspense>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppComponent));
