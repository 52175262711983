export const ADD_EMPLOYEE = 'admin/ADD_DATA_ITEM';
export const ADD_EMPLOYEE_SUCCESS = 'admin/ADD_DATA_ITEM_SUCCESS';
export const ADD_EMPLOYEE_FAILURE = 'admin/ADD_DATA_ITEM_FAILURE';

export const REMOVE_EMPLOYEE = 'admin/REMOVE_DATA_ITEM';
export const REMOVE_EMPLOYEE_SUCCESS = 'admin/REMOVE_DATA_ITEM_SUCCESS';
export const REMOVE_EMPLOYEE_FAILURE = 'admin/REMOVE_DATA_ITEM_FAILURE';

export const UPDATE_EMPLOYEE = 'admin/UPDATE_DATA_ITEM';
export const UPDATE_EMPLOYEE_SUCCESS = 'admin/UPDATE_DATA_ITEM_SUCCESS';
export const UPDATE_EMPLOYEE_FAILURE = 'admin/UPDATE_DATA_ITEM_FAILURE';

export const ADD_CATEGORY = 'admin/rewards/ADD_DATA_ITEM';
export const ADD_CATEGORY_SUCCESS = 'admin/rewards/ADD_DATA_ITEM_SUCCESS';
export const ADD_CATEGORY_FAILURE = 'admin/rewards/ADD_DATA_ITEM_FAILURE';

export const REMOVE_CATEGORY = 'admin/rewards/REMOVE_DATA_ITEM';
export const REMOVE_CATEGORY_SUCCESS = 'admin/rewards/REMOVE_DATA_ITEM_SUCCESS';
export const REMOVE_CATEGORY_FAILURE = 'admin/rewards/REMOVE_DATA_ITEM_FAILURE';

export const UPDATE_CATEGORY = 'admin/rewards/UPDATE_DATA_ITEM';
export const UPDATE_CATEGORY_SUCCESS = 'admin/rewards/UPDATE_DATA_ITEM_SUCCESS';
export const UPDATE_CATEGORY_FAILURE = 'admin/rewards/UPDATE_DATA_ITEM_FAILURE';
