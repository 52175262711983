import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { blue, red } from '@material-ui/core/colors';

export const Version = 1;
const colors = {
  primaryColor: blue[500],
  secondaryColor: red[500],
  tableLabelColor: '#808080',
  addButtonBackground: '#FEF1ED',
  addButtonTextColor: '#F88566',
  addButtonBackgroundHover: '#dfb4a7',
  addButtonTextColorHover: '#f5f2f1',
  viewButtonColor: '#7CAFEB',
  deleteIconColor: '#D64A61',
  appBarColorWithoutSignIN: '#ffffff',
  appBarColorWithSignIn: '#000000',
  bronzeBadgeColor: '#885308',
  silverBadgeColor: '#48494A',
  goldBadgeColor: '#885308',
  PlatinumBadge: '#48494A',
  podiumPointsColor: '#E66C40',
  lineColor: '#DBDBDB',
  bronzeText: '#784040',
  goldText: '#EE9B25',
  silverText: '#B1B1AE',
  platinumText: '#554F4F',
  earnPointsBackground: '#F4F2F2',
  progressBarColor: '#D8D8D8',
  profileBorderColor: '#BE465A',
  titleTextColor: '#48494A',
  checkpointColor: '#C1BEBE',
  appreciateMain: '#1CD0D7',
  appreciateModal: '#E9FBFB',
  appreciateModalExit: '#19BBC1',
  footprintBaseColor: '#C5C5C566',
  playOnBackgroundColor: '#002B67',
  notificationMessageBackground: '#E8FBFB',
  notificationMessage: '#17A1A7',
  lightGrayForBorder: '#D3D3D3',
  lightPink: '#FAF8F8',
  gray: '#C4C4C4',
  appreciateFeatureBackgroundColor: '#D4F6F8',
  competeFeatureBackgroundColor: '#FED2C6',
  rewardFeatureBackgroundColor: '#F9EED2',
  redemptionFeatureBackgroundColor: '#E6E9E9',
  appreciateFeatureIconBackgroundColor: '#35AEB4',
  competeFeatureIconBackgroundColor: '#F77754',
  rewardFeatureIconBackgroundColor: '#FFE381',
  redemptionFeatureIconBackgroundColor: '#FFFFFF',
  lightCyan: '#F1F8F8',
  pink: '#FFEBE5',
  darkGray: '#848484',
  messageBox: '#979797',
  yellowBadge: '#F5B500',
  metallicBlue: '#2E4C75',
  azurishWhite: '#DDEEED'
};

let baseTheme = createMuiTheme({
  typography: {
    fontFamily: ['Montserrat', 'Arial', 'sans-serif'].join(','),
    useNextVariants: true,
    body1: {
      fontSize: '.9rem'
    }
  },
  palette: {
    primary: {
      main: '#F15858'
    },
    secondary: {
      main: '#002B67'
    },
    white: {
      main: '#fff'
    },
    springWood: {
      main: '#f6f4ed'
    },
    black: {
      main: '#333333'
    },
    gray: {
      main: '#B6B5B5',
      secondary: '#707070'
    }
  },
  colors: {
    ...colors
  },
  addButton: {
    padding: '5px 5px 5px 5px',
    borderRadius: '5px'
  },
  viewButton: {
    padding: '6px 15px 0px 0px'
  }
});
baseTheme = responsiveFontSizes(baseTheme);
export const muiTheme = {
  ...baseTheme,
  overrides: {
    MuiTypography: {
      root: {
        overflowWrap: 'break-word'
      },
      colorInherit: {
        color: 'white'
      }
    },
    MuiTableSortLabel: {
      root: {
        '&$active': {
          color: 'grey',
          '&& $icon': {
            color: '#CC6540'
          }
        }
      }
    },
    MuiTreeItem: {
      root: {
        '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
          fontWeight: 'bold'
        },
        '&.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
          backgroundColor: 'transparent'
        },
        '&.Mui-selected > .MuiTreeItem-content': {
          backgroundColor: 'rgba(255, 255, 255, .1) !important'
        }
      },
      group: {
        marginLeft: '36px'
      },
      content: {
        paddingTop: '16px',
        paddingRight: '16px',
        paddingBottom: '16px',
        paddingLeft: '32px',
        color: baseTheme.palette.white.main,
        // backgroundColor: baseTheme.palette.secondary.main,
        '&:hover': { backgroundColor: baseTheme.palette.secondary.main }
      },
      label: {
        marginLeft: '5px'
      },
      selected: {
        borderLeft: `5px solid ${baseTheme.palette.secondary.main}`,
        backgroundColor: baseTheme.palette.secondary.main
      }
    }
  }
};
