const storeErrorObj = { error: 'Store to localStorage failed. Invalid key or value.' };
const deleteErrorObj = { error: 'Delete from localStorage failed. Invalid key.' };

// Check for support
const isLocalStorageSupported = () => !!window.localStorage;

// Create a record
const createOrUpdateRecord = (key, value) => {
  if (!key || !value) {
    return storeErrorObj;
  }
  const stringifiedValue = JSON.stringify(value);
  localStorage.setItem(key, stringifiedValue);
  return true;
};

// Read a record
export const readRecord = key => {
  const recordObjString = localStorage.getItem(key);
  if (!recordObjString) return null;
  return JSON.parse(recordObjString) || {};
};

// Delete a record
export const deleteRecord = key => {
  if (!key) {
    return deleteErrorObj;
  }
  localStorage.removeItem(key);
  return true;
};

// Clear all
export const clearAll = () => localStorage.clear();

// Check for stored items
export const hasStoredItems = () => localStorage.length > 0;

// Store (create or update) record to local storage
export const storeRecord = (key, value) => {
  if (isLocalStorageSupported()) {
    return createOrUpdateRecord(key, value);
  }
  return false;
};
