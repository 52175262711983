import dayJs from 'dayjs';

const findAccessTokenFromJson = jsonObj => {
  let result;
  const objKeys = Object.keys(jsonObj);
  if (objKeys.includes('access_token')) {
    result = jsonObj.access_token;
  } else {
    for (let index = 0; index < objKeys.length; index += 1) {
      if (typeof jsonObj[objKeys[index]] === 'object') {
        const val = findAccessTokenFromJson(jsonObj[objKeys[index]]);
        if (val !== undefined) {
          result = val;
          break;
        }
      }
    }
  }
  return result;
};

const sortRowsByKeyString = (rows, isOrderAscending, key) => {
  if (isOrderAscending === true) {
    rows.sort((a, b) => {
      if (b[key].toLowerCase() > a[key].toLowerCase()) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    });
  } else {
    rows.sort((a, b) => {
      if (a[key].toLowerCase() > b[key].toLowerCase()) {
        return -1;
      }
      if (b[key] > a[key]) {
        return 1;
      }
      return 0;
    });
  }
  return rows;
};

const sortRowsByKeyNumber = (rows, isOrderAscending, key) => {
  if (isOrderAscending === true) {
    rows.sort((a, b) => {
      return a[key] - b[key];
    });
  } else {
    rows.sort((a, b) => {
      return b[key] - a[key];
    });
  }

  return rows;
};

const sortRowsByKeyDate = (rows, isOrderAscending, key) => {
  if (isOrderAscending === true) {
    rows.sort((a, b) => {
      return new Date(a[key]) - new Date(b[key]);
    });
  } else {
    rows.sort((a, b) => {
      return new Date(b[key]) - new Date(a[key]);
    });
  }

  return rows;
};

const filterRowsUsingString = (rows, str, rowLabel = 'name') => {
  const filteredRows = rows.filter(data => {
    return data[rowLabel].toLowerCase().includes(str.toLowerCase());
  });
  return filteredRows;
};

const deDuplicateArrayByKey = (rows, rowLabel = 'name') => {
  const filteredRows = rows.filter(
    (data, index, accumulator) =>
      accumulator.findIndex(t => t[rowLabel] === data[rowLabel]) === index
  );
  return filteredRows;
};

const groupCategories = data => {
  const categoryObj = {};
  data.forEach(d => {
    if (Object.hasOwnProperty.call(categoryObj, d?.category?.description)) {
      categoryObj[d?.category?.description] += 1;
    } else {
      categoryObj[d?.category?.description] = 1;
    }
  });
  return categoryObj;
};

const sortObjectAlphabatecially = data => {
  const ordered = {};
  Object.keys(data)
    .sort()
    .forEach(key => {
      ordered[key] = data[key];
    });
  return ordered;
};

const findEmpFromListWithEmail = (empList = [], email = '', nameAndID = false) => {
  if (!empList.length) return email;
  const empData = empList.find(e => e.email === email);
  if (empData) {
    if (nameAndID) return { name: empData.name, empId: empData.id };
    return empData.name;
  }
  return email;
};

const parseAndFormatPointsData = (data, empList, addedById = false) => {
  const sortedData = data.sort((a, b) =>
    new Date(b.reward_date).getTime() === new Date(a.reward_date).getTime()
      ? b.point - a.point
      : new Date(b.reward_date) - new Date(a.reward_date)
  );

  sortedData.forEach(d => {
    if (!d.name && d.employee) d.name = d.employee.name;
    if (addedById) {
      const { name = d.created_by, empId = '-' } = findEmpFromListWithEmail(
        empList,
        d.created_by,
        true
      );
      d.addedById = empId;
      d.addedBy = name;
    } else {
      d.addedBy = findEmpFromListWithEmail(empList, d.created_by);
    }
    d.rewardDate = dayJs(d.reward_date).format('DD/MM/YYYY');
  });

  return sortedData;
};

const parseStringToInteger = string => {
  return parseInt(string, 10);
};

const ordinal = number => {
  const englishOrdinalRules = new Intl.PluralRules('en', {
    type: 'ordinal'
  });
  const suffixes = {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th'
  };
  const suffix = suffixes[englishOrdinalRules.select(number)];
  return number + suffix;
};

function toRoman(num) {
  let number = num;
  const decimalValue = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
  const romanNumeral = ['M', 'CM', 'D', 'CD', 'C', 'XC', 'L', 'XL', 'X', 'IX', 'V', 'IV', 'I'];

  let romanized = '';

  for (let index = 0; index < decimalValue.length; index += 1) {
    while (decimalValue[index] <= number) {
      romanized += romanNumeral[index];
      number -= decimalValue[index];
    }
  }

  return romanized;
}

function newHeader(url) {
  const urls = ['/', '/reward-dashboard', '/profile', '/redeem'];
  if (urls.includes(url)) {
    return false;
  }
  return true;
}
function showDrawer(url, isLogin) {
  const urls = ['/home'];
  if (urls.includes(url)) {
    return false;
  }
  if (isLogin) {
    return true;
  }
  return false;
}

function badge(level) {
  const badges = ['Bronze', 'Silver', 'Gold', 'Platinum'];
  if (level === -1) {
    return null;
  }
  return badges[level];
}

export {
  findAccessTokenFromJson,
  sortRowsByKeyString,
  sortRowsByKeyNumber,
  filterRowsUsingString,
  groupCategories,
  parseAndFormatPointsData,
  parseStringToInteger,
  sortObjectAlphabatecially,
  sortRowsByKeyDate,
  deDuplicateArrayByKey,
  ordinal,
  toRoman,
  newHeader,
  showDrawer,
  badge
};
