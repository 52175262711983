export const LOAD_EMPLOYEE_LIST = 'employee/LOAD_DATA_BUNCH';
export const LOAD_EMPLOYEE_LIST_SUCCESS = 'employee/LOAD_DATA_BUNCH_SUCCESS';
export const LOAD_EMPLOYEE_LIST_FAILURE = 'employee/LOAD_DATA_BUNCH_FAILURE';

export const LOAD_REWARDS_LIST = 'rewards/LOAD_DATA_BUNCH';
export const LOAD_REWARDS_LIST_SUCCESS = 'rewards/LOAD_DATA_BUNCH_SUCCESS';
export const LOAD_REWARDS_LIST_FAILURE = 'rewards/LOAD_DATA_BUNCH_FAILURE';

export const LOAD_CATEGORIES_LIST = 'employeeRewards/LOAD_DATA_ITEM';
export const LOAD_CATEGORIES_LIST_SUCCESS = 'employeeRewards/LOAD_DATA_ITEM_SUCCESS';
export const LOAD_CATEGORIES_LIST_FAILURE = 'employeeRewards/LOAD_DATA_ITEM_FAILURE';

export const HIGHLIGHT_EMPLOYEE = 'employeeRewards/SELECT_DATA_ITEM';
export const UNHIGHLIGHT_EMPLOYEE = 'employeeRewards/DESELECT_DATA_ITEM';

export const HIGHLIGHT_CATEGORY = 'rewardCategory/SELECT_DATA_ITEM';
export const UNHIGHLIGHT_CATEGORY = 'rewardCategory/DESELECT_DATA_ITEM';

export const ADD_REWARD = 'employeeRewards/ADD_DATA_ITEM';
export const ADD_REWARD_SUCCESS = 'employeeRewards/ADD_DATA_ITEM_SUCCESS';
export const ADD_REWARD_FAILURE = 'employeeRewards/ADD_DATA_ITEM_FAILURE';

export const REMOVE_REWARD = 'employeeRewards/REMOVE_DATA_ITEM';
export const REMOVE_REWARD_SUCCESS = 'employeeRewards/REMOVE_DATA_ITEM_SUCCESS';
export const REMOVE_REWARD_FAILURE = 'employeeRewards/REMOVE_DATA_ITEM_FAILURE';

export const CLEAR_EMPLOYEE_LIST = 'employeeRewards/CLEAR_DATA_BUNCH';
export const CLEAR_REWARDS_LIST = 'employeeRewards/CLEAR_DATA_BUNCH';

export const LOAD_APPRECIATION_LIST = 'employeeRewards/appreciationsList/LOAD_DATA_BUNCH';
export const LOAD_APPRECIATION_LIST_SUCCESS =
  'employeeRewards/appreciationsList/LOAD_DATA_BUNCH_SUCCESS';
export const LOAD_APPRECIATION_LIST_FAILURE =
  'employeeRewards/appreciationsList/LOAD_DATA_BUNCH_FAILURE';

export const LOAD_APPRECIATION_TRANSACTION_LIST = 'rewards/appreciationsList/LOAD_DATA_BUNCH';
export const LOAD_APPRECIATION_TRANSACTION_LIST_SUCCESS =
  'rewards/appreciationsList/LOAD_DATA_BUNCH_SUCCESS';
export const LOAD_APPRECIATION_TRANSACTION_LIST_FAILURE =
  'rewards/appreciationsList/LOAD_DATA_BUNCH_FAILURE';

export const LOAD_PIGGY_BANK_BALANCE_LIST = 'rewards/piggyBank/LOAD_DATA_BUNCH';
export const LOAD_PIGGY_BANK_BALANCE_LIST_SUCCESS = 'rewards/piggyBank/LOAD_DATA_BUNCH_SUCCESS';
export const LOAD_PIGGY_BANK_BALANCE_LIST_FAILURE = 'rewards/piggyBank/LOAD_DATA_BUNCH_FAILURE';

export const LOAD_LEADERBOARD_DATA = 'rewardDashboard/LOAD_DATA_BUNCH';
export const LOAD_LEADERBOARD_DATA_SUCCESS = 'rewardDashboard/LOAD_DATA_BUNCH_SUCCESS';
export const LOAD_LEADERBOARD_DATA_FAILURE = 'rewardDashboard/LOAD_DATA_BUNCH_FAILURE';

export const LOAD_PROFILE_DATA = 'rewardHeader/LOAD_DATA_BUNCH';
export const LOAD_PROFILE_DATA_SUCCESS = 'rewardHeader/LOAD_DATA_BUNCH_SUCCESS';
export const LOAD_PROFILE_DATA_FAILURE = 'rewardHeader/LOAD_DATA_BUNCH_FAILURE';

export const LOAD_CHECKPOINT_DATA = 'playOn/LOAD_DATA_BUNCH';
export const LOAD_CHECKPOINT_DATA_SUCCESS = 'playOn/LOAD_DATA_BUNCH_SUCCESS';
export const LOAD_CHECKPOINT_DATA_FAILURE = 'playOn/LOAD_DATA_BUNCH_FAILURE';

export const APPRECIATE = 'employeeRewards/ADD_DATA_ITEM';
export const APPRECIATE_SUCCESS = 'employeeRewards/ADD_DATA_ITEM_SUCCESS';
export const APPRECIATE_FAILURE = 'employeeRewards/ADD_DATA_ITEM_FAILURE';

export const LOAD_UPCOMING_REWARDS_LIST = 'upcomingRewards/LOAD_DATA_BUNCH';
export const LOAD_UPCOMING_REWARDS_LIST_SUCCESS = 'upcomingRewards/LOAD_DATA_BUNCH_SUCCESS';
export const LOAD_UPCOMING_REWARDS_LIST_FAILURE = 'upcomingRewards/LOAD_DATA_BUNCH_FAILURE';

export const REDEEM_REWARD = 'redeemReward/LOAD_DATA_BUNCH';
export const REDEEM_REWARD_SUCCESS = 'redeemReward/LOAD_DATA_BUNCH_SUCCESS';
export const REDEEM_REWARD_FAILURE = 'redeemReward/LOAD_DATA_BUNCH_FAILURE';
