import React from 'react';

const RewardPageContainer = React.lazy(() =>
  import('../Containers/RewardPage/RewardPageContainer')
);
const CategoryPageContainer = React.lazy(() =>
  import('../Containers/CategoryPage/CategoryPageContainer')
);
const PiggyBankBalancePageContainer = React.lazy(() =>
  import('../Containers/PiggyBankBalancePage/PiggyBankBalancePageContainer')
);

const RewardDashboardPageContainer = React.lazy(() =>
  import('../Containers/RewardDashboardPage/RewardDashboardPageContainer')
);

const PiggyBankTransactionPageContainer = React.lazy(() =>
  import('../Containers/PiggyBankTransactionPage/PiggyBankTransactionPageContainer')
);
const AboutPageContainer = React.lazy(() => import('../Containers/AboutPage/AboutPageContainer'));

const SigninComponent = React.lazy(() => import('../Containers/Signin/SigninComponent'));
const NotFoundComponent = React.lazy(() => import('../Components/NotFound/NotFoundComponent'));
const AppHomeContainer = React.lazy(() => import('../Containers/AppHome/AppHomeContainer'));

const ProfilePageContainer = React.lazy(() =>
  import('../Containers/ProfilePage/ProfilePageContainer')
);

const ReedemPageContainer = React.lazy(() => import('../Containers/RedemPage/RedemPageContainer'));

export const RouteUrls = {
  appHome: '/',
  home: '/home',
  login: '/login',
  reward: '/reward',
  categories: '/categories',
  piggyBankBalance: '/piggy-bank-balance',
  piggyBankTransactions: '/piggy-bank-transactions',
  rewardDashboard: '/reward-dashboard',
  aboutPage: '/about',
  profile: '/profile',
  reedem: '/redeem'
};

const Routes = [
  {
    path: RouteUrls.appHome,
    exact: true,
    component: AppHomeContainer
  },
  {
    path: RouteUrls.home,
    exact: true,
    component: AppHomeContainer
  },
  {
    path: RouteUrls.login,
    exact: true,
    component: SigninComponent
  },
  {
    path: RouteUrls.reward,
    exact: true,
    component: RewardPageContainer
  },
  {
    path: RouteUrls.categories,
    exact: true,
    component: CategoryPageContainer
  },
  {
    path: RouteUrls.piggyBankBalance,
    exact: true,
    component: PiggyBankBalancePageContainer
  },
  {
    path: RouteUrls.piggyBankTransactions,
    exact: true,
    component: PiggyBankTransactionPageContainer
  },
  {
    path: RouteUrls.rewardDashboard,
    exact: true,
    component: RewardDashboardPageContainer
  },
  {
    path: RouteUrls.aboutPage,
    exact: true,
    component: AboutPageContainer
  },
  {
    path: RouteUrls.profile,
    exact: true,
    component: ProfilePageContainer
  },
  {
    path: RouteUrls.redeem,
    exact: true,
    component: ReedemPageContainer
  },
  {
    path: '*',
    component: NotFoundComponent
  }
];

export default Routes;
