import {
  parseAndFormatPointsData,
  groupCategories,
  sortObjectAlphabatecially
} from '../../../Services/UtilService/UtilService';

import * as types from '../../Types';

const initialState = {
  employeeData: [],
  headerData: [],
  leaderboardData: [],
  selectedEmployee: {},
  selectedCategory: {},
  categoryList: [],
  appreciationList: [],
  appreciationTransactionList: [],
  pbBalanceList: [],
  loading: null,
  error: ''
};

const EmployeeRewardsReducer = (state = initialState, action) => {
  let result;

  switch (action.type) {
    case types.LOAD_PROFILE_DATA:
      return {
        ...state,
        headerData: [],
        loading: true,
        error: ''
      };

    case types.LOAD_PROFILE_DATA_SUCCESS: {
      result = action.result || [];
      return {
        ...state,
        headerData: result,
        loading: false
      };
    }

    case types.LOAD_EMPLOYEE_LIST:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case types.LOAD_EMPLOYEE_LIST_SUCCESS: {
      result = action.result || [];
      return {
        ...state,
        employeeData: result,
        loading: false
      };
    }

    case types.LOAD_CATEGORIES_LIST:
      return {
        ...state,
        categoryList: [],
        loading: true,
        error: ''
      };

    case types.LOAD_CATEGORIES_LIST_SUCCESS: {
      result = action.result || [];
      return {
        ...state,
        categoryList: result,
        loading: false
      };
    }

    case types.LOAD_LEADERBOARD_DATA:
      return {
        ...state,
        leaderboardData: [],
        loading: true,
        error: ''
      };

    case types.LOAD_LEADERBOARD_DATA_SUCCESS: {
      result = action.result || [];
      return {
        ...state,
        leaderboardData: result,
        loading: false
      };
    }

    case types.LOAD_CHECKPOINT_DATA:
      return {
        ...state,
        checkpointData: {},
        loading: true,
        error: ''
      };

    case types.LOAD_CHECKPOINT_DATA_SUCCESS: {
      result = action.result || {};
      return {
        ...state,
        checkpointData: result,
        loading: false
      };
    }

    case types.LOAD_UPCOMING_REWARDS_LIST:
      return {
        ...state,
        upcomingRewardsData: [],
        loading: true,
        error: ''
      };

    case types.LOAD_UPCOMING_REWARDS_LIST_SUCCESS: {
      result = action.result || [];
      return {
        ...state,
        upcomingRewardsData: result,
        loading: false
      };
    }

    case types.ADD_EMPLOYEE:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case types.ADD_EMPLOYEE_SUCCESS: {
      result = action.result || [];
      return {
        ...state,
        loading: false
      };
    }

    case types.ADD_CATEGORY:
    case types.UPDATE_CATEGORY:
    case types.REMOVE_CATEGORY:
    case types.REMOVE_EMPLOYEE:
    case types.UPDATE_EMPLOYEE:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case types.ADD_CATEGORY_SUCCESS:
    case types.UPDATE_CATEGORY_SUCCESS:
    case types.REMOVE_CATEGORY_SUCCESS:
    case types.REMOVE_EMPLOYEE_SUCCESS:
    case types.UPDATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case types.CLEAR_EMPLOYEE_LIST:
      return {
        ...state,
        employeeData: []
      };

    case types.HIGHLIGHT_EMPLOYEE:
    case types.LOAD_REWARDS_LIST:
      result = action.payload || { name: '', id: '?' };
      return {
        ...state,
        selectedEmployee: result,
        loading: action.type === types.LOAD_REWARDS_LIST,
        error: ''
      };

    case types.LOAD_REWARDS_LIST_SUCCESS: {
      result = action.result || [];
      const pointsList = parseAndFormatPointsData(result, state.employeeData);

      const categoryCounts = groupCategories(result);
      const sortedCategoryCount = sortObjectAlphabatecially(categoryCounts);
      return {
        ...state,
        selectedEmployee: {
          ...state.selectedEmployee,
          pointsList,
          categoryCounts: sortedCategoryCount
        },
        loading: false
      };
    }

    case types.UNHIGHLIGHT_EMPLOYEE:
    case types.CLEAR_REWARDS_LIST:
      return {
        ...state,
        selectedEmployee: {}
      };

    case types.ADD_REWARD:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case types.ADD_REWARD_SUCCESS: {
      result = action.result || [];
      return {
        ...state,
        loading: false
      };
    }
    case types.REMOVE_REWARD: {
      return {
        ...state,
        loading: true
      };
    }
    case types.REMOVE_REWARD_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }

    case types.LOAD_APPRECIATION_LIST:
      return {
        ...state,
        appreciationList: [],
        loading: true,
        error: ''
      };

    case types.LOAD_APPRECIATION_LIST_SUCCESS: {
      result = action.result || [];
      const parsedAppreciationList = parseAndFormatPointsData(result, state.employeeData);
      return {
        ...state,
        appreciationList: parsedAppreciationList,
        loading: false
      };
    }

    case types.LOAD_APPRECIATION_TRANSACTION_LIST:
      return {
        ...state,
        appreciationTransactionList: [],
        loading: true,
        error: ''
      };

    case types.LOAD_APPRECIATION_TRANSACTION_LIST_SUCCESS: {
      result = action.result || [];
      const parsedAppreciationTransactionList = parseAndFormatPointsData(
        result,
        state.employeeData,
        true
      );
      return {
        ...state,
        appreciationTransactionList: parsedAppreciationTransactionList,
        loading: false
      };
    }

    case types.LOAD_PIGGY_BANK_BALANCE_LIST:
      return {
        ...state,
        pbBalanceList: [],
        loading: true,
        error: ''
      };

    case types.LOAD_PIGGY_BANK_BALANCE_LIST_SUCCESS: {
      result = action.result || [];
      const parsedPiggyBankList = result.map(res => ({
        pbBalance: res.pb_balance,
        ...res.employee
      }));
      return {
        ...state,
        pbBalanceList: parsedPiggyBankList,
        loading: false
      };
    }

    case types.HIGHLIGHT_CATEGORY:
      result = action.payload || {};
      return {
        ...state,
        selectedCategory: result
      };

    case types.UNHIGHLIGHT_CATEGORY:
      return {
        ...state,
        selectedCategory: {}
      };

    case types.APPRECIATE:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case types.APPRECIATE_SUCCESS: {
      result = action.result || [];
      return {
        ...state,
        loading: false
      };
    }

    case types.REDEEM_REWARD: {
      return {
        ...state,
        loading: true
      };
    }
    case types.REDEEM_REWARD_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }

    case types.APPRECIATE_FAILURE: {
      const respError = action?.error?.response?.data.message;
      const errorMessage = action?.error?.message;
      return {
        ...state,
        loading: false,
        error: respError || errorMessage
      };
    }

    case types.ADD_REWARD_FAILURE: {
      const respError = action?.error?.response?.data.message;
      const errorMessage = action?.error?.message;
      return {
        ...state,
        loading: false,
        error: respError || errorMessage
      };
    }

    case types.REMOVE_REWARD_FAILURE:
    case types.LOAD_REWARDS_LIST_FAILURE:
    case types.REMOVE_EMPLOYEE_FAILURE:
    case types.ADD_EMPLOYEE_FAILURE:
    case types.ADD_CATEGORY_FAILURE:
    case types.UPDATE_CATEGORY_FAILURE:
    case types.REMOVE_CATEGORY_FAILURE:
    case types.LOAD_CATEGORIES_LIST_FAILURE:
    case types.LOAD_EMPLOYEE_LIST_FAILURE:
    case types.LOAD_APPRECIATION_LIST_FAILURE:
    case types.LOAD_APPRECIATION_TRANSACTION_LIST_FAILURE:
    case types.LOAD_LEADERBOARD_DATA_FAILURE:
    case types.LOAD_PROFILE_DATA_FAILURE:
    case types.LOAD_CHECKPOINT_DATA_FAILURE:
    case types.LOAD_UPCOMING_REWARDS_LIST_FAILURE:
    case types.REDEEM_REWARD_FAILURE:
    case types.UPDATE_EMPLOYEE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error && action.error.message
      };
    }

    case types.DESTROY_SESSION:
      return {
        ...initialState
      };

    default:
      return state;
  }
};

export default EmployeeRewardsReducer;
